import { User } from "../../../../generated/graphql";

export class SchoolAdminResolver {
  private constructor() {}

  private static getFirstCreatedUser(users: User[] = []) {
    return [...users]
      .sort((a, b) => +new Date(a.createdDate).getTime() - +new Date(b.createdDate).getTime())[0];
  }

  private static getResolvedField(admins: User[]) {
    return SchoolAdminResolver.getFirstCreatedUser(admins);
  }

  private static getStringFromFieldData(field: User) {
    return field ? `${field?.firstName} ${field?.lastName}` : "";
  }

  static getDataForSingleSchool(admins: User[]) {
    const resolvedField = SchoolAdminResolver.getResolvedField(admins);
    return SchoolAdminResolver.getStringFromFieldData(resolvedField);
  }

  static getDataForSchools(admins: User[]) {
    const resolvedField = SchoolAdminResolver.getResolvedField(admins);
    return [
      `${SchoolAdminResolver.getStringFromFieldData(resolvedField)}`,
      `${resolvedField?.phone || ""} ${resolvedField?.email || ""}`,
    ];
  }
}
