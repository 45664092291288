import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useMutation, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";
import {
  AvatarContainer,
  InfoContainer,
  StyledAvatar,
} from "./CurrentUser.style";
import { LOGOUT } from "../../graphql/mutations/auth";
import { ErrorSnackbar } from "../shared/Error/ErrorSnackbar";
import { GET_USER_INFO } from "../../graphql/queries/user";
import { LogoutMutation } from "../../generated/graphql";
import { GetUserInfoType } from "../shared/Type/Type";
import { DEFAULT_AVATAR_URL } from "../shared/constants";

export function CurrentUser() {
  const { data, loading, error } = useQuery<GetUserInfoType>(GET_USER_INFO);

  const [logout, { client, data: logoutSuccess }] =
    useMutation<LogoutMutation>(LOGOUT);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (logoutSuccess) {
    return <Redirect to="/login" />;
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    localStorage.removeItem("personalSchoolsFilters");
    localStorage.removeItem("schoolsFilters");

    await logout();
    await client.resetStore();
    handleClose();
  };

  if (error) {
    return <ErrorSnackbar error={error} />;
  }

  if (loading) {
    return null;
  }

  return (
    <AvatarContainer>
      <InfoContainer>
        <div className="InfoContainer__name">
          {data?.getUserInfo?.firstName}
        </div>
        <div className="InfoContainer__name">{data?.getUserInfo?.lastName}</div>
        {/*<div className="InfoContainer__role">{data?.role.displayName}</div>*/}
      </InfoContainer>

      <StyledAvatar
        src={
          data?.getUserInfo?.photo
            ? data?.getUserInfo?.photo
            : DEFAULT_AVATAR_URL
        }
        onClick={handleMenu}
      >
        {data?.getUserInfo?.firstName} {data?.getUserInfo?.lastName}
      </StyledAvatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Выйти</MenuItem>
      </Menu>
    </AvatarContainer>
  );
}
