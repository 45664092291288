import { useState, useContext, useMemo, useEffect } from "react";
import {
  useGetCoursesForAdminPanelQuery,
  UserRoleEnum,
} from "../../../generated/graphql";
import { UserInfoContext } from "../../Main";
import { Box, Grid } from "@material-ui/core";
import { ButtonCustom, Subtitle } from "../../shared/Style/Style";
import { SelectCourses } from "../Selectors";
import { blockNameText } from "./constants";
import { useParams } from "react-router-dom";
import { SuccessSnackbar } from "../../shared/SuccessSnackbar";
import { LoadingProgress } from "../../shared/LoadingProgress";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { useRemoveCourse } from "./useRemoveCourse";
import { useAddCourse } from "./useAddCourse";
import { getAvailableCourses, getCourseById, getCourseErrorMessage } from "./utils";
import { CourseActionType, CourseItemType } from "./types";

export const Courses = () => {
  const context = useContext(UserInfoContext);
  const isManagerRole = context?.role?.role === UserRoleEnum.Manager;
  const { schoolId } = useParams<{ schoolId: string }>();
  const [courseId, setCourseId] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useGetCoursesForAdminPanelQuery({
    fetchPolicy: "network-only",
    variables: {
      schoolId,
    },
    onCompleted: () => {
      if (coursesError) setErrorMessage("При загрузке списка курсов произошла ошибка");
    }
  });
  const courses = useMemo(
    () =>
      (coursesData?.getCoursesForAdminPanel ?? []).sort((item) =>
        item?.courseIsAvailable ? -1 : 1
      ),
    [coursesData?.getCoursesForAdminPanel]
  );
  const availableCourses = getAvailableCourses(courses);
  const activeCourse = getCourseById(courses, courseId);
  const removeCourse = useRemoveCourse(schoolId);
  const addCourse = useAddCourse(schoolId);

  const handleRemoveCourses = async (courses: CourseItemType[]) => {
    const coursesForDeactivation = getAvailableCourses(courses);
    for await (const { id } of coursesForDeactivation) {
      const res = await removeCourse(schoolId, id);

      if (res?.errors) {
        return setErrorMessage(
          getCourseErrorMessage(res?.errors, CourseActionType.Remove)
        );
      }

      if (courseId === id) setSuccessMessage("Курс - деактивирован");
    }
  };

  const handleClick = async () => {
    const res = await addCourse(schoolId, courseId);

    if (res?.errors) {
      setErrorMessage(
        getCourseErrorMessage(res?.errors, CourseActionType.Add)
      );
    }

    if (res?.data?.addCourseToSchool) {
      const coursesForDeactivation = availableCourses.filter(
        (course) => course?.id !== courseId
      );

      setSuccessMessage(`${getCourseById(courses, courseId).name} - активирован`);
      await handleRemoveCourses(coursesForDeactivation);
    }
  };

  if (coursesLoading) return <LoadingProgress />;
  if (!isManagerRole || !courses.length) return null;
  return (
    <>
      <Box pr={3.5} pl={3.5}>
        <Subtitle>{blockNameText}</Subtitle>
        <Box mt={2.5} mb={1.5}>
          <SelectCourses
            courses={courses}
            activeCourse={activeCourse}
            setCourseId={setCourseId}
            onRemoveCourse={() => handleRemoveCourses(courses)}
          />
        </Box>
        <Grid xs={12} item>
          <ButtonCustom
            onClick={handleClick}
            disabled={
              coursesLoading || !activeCourse || activeCourse?.courseIsAvailable
            }
            width="200"
          >
            Открыть доступ
          </ButtonCustom>
        </Grid>
      </Box>

      <ErrorMessageSnackbar
        open={!!errorMessage}
        message={errorMessage}
        closeHandler={() => setErrorMessage("")}
      />
      <SuccessSnackbar
        open={!!successMessage}
        message={successMessage}
        handleClose={() => setSuccessMessage("")}
      />
    </>
  );
};
