import { useMemo } from "react";
import { Field, Form } from "react-final-form";
import {
  SubscriptionPlanPaymentData,
  SubscriptionPlan,
  PaymentType,
} from "../../../../generated/graphql";
import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ButtonCustom, ModalInput } from "../../../shared/Style/Style";
import { validateForm, SubscriptionFormValues } from "./utils";
import { CommonPaymentTypeMap, PersonalPaymentTypeMap } from "../maps";
import { costText } from "../../constants";

type CreatePaymentData = {
  data: Partial<SubscriptionPlanPaymentData>;
};

interface SubscriptionFormProps {
  subscriptionPlans: SubscriptionPlan[];
  isPersonal: boolean;
  disableSubmit: boolean;
  createPayment(variables: CreatePaymentData): void;
}

const formInitialValues: SubscriptionFormValues = {
  paymentDate: "",
  validity: "",
  paymentType: "",
  planId: "",
  pupilsCount: "",
  teachersCount: "",
  total: "",
};

export const SubscriptionForm = ({
  subscriptionPlans = [],
  isPersonal,
  disableSubmit,
  createPayment,
}: SubscriptionFormProps) => {
  const paymentTypes = useMemo(
    () =>
      Array.from([
        ...(isPersonal ? PersonalPaymentTypeMap : []),
        ...CommonPaymentTypeMap,
      ]),
    [isPersonal]
  );

  const onFormValidation = (values: SubscriptionFormValues) =>
    validateForm(values, isPersonal);

  const onFormSubmit = (values: SubscriptionFormValues) => {
    const {
      paymentDate,
      validity,
      paymentType,
      planId,
      pupilsCount,
      teachersCount,
      total,
    } = values;

    if (!validity) return;
    else if (isNaN(Number(validity))) return;

    createPayment({
      data: {
        paymentDate,
        validity: +validity,
        paymentType: paymentType as PaymentType,
        planId,
        ...(!isPersonal
          ? {
              pupilsCount: !isNaN(Number(pupilsCount)) ? +pupilsCount : 0,
              teachersCount: !isNaN(Number(teachersCount)) ? +teachersCount : 0,
            }
          : {}),
        total: !isNaN(Number(total)) ? +total : 0,
      },
    });
  };

  return (
    <Form
      initialValues={formInitialValues}
      validate={onFormValidation}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid xs={6} item>
              <Field name="paymentDate">
                {({ input, meta }) => (
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    variant="inline"
                    format="dd.MM.yyyy"
                    label="Дата платежа"
                    inputVariant="filled"
                    value={input.value || null}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="validity">
                {({ input, meta }) => (
                  <ModalInput
                    label="Кол-во месяцев"
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="planId">
                {({ input, meta }) => (
                  <FormControl
                    variant="filled"
                    error={meta.error && meta.touched}
                    fullWidth
                  >
                    <InputLabel>Тариф</InputLabel>
                    <Select
                      fullWidth
                      value={input.value.id}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                      }}
                    >
                      {subscriptionPlans.map(({ id, name, description }) => (
                        <MenuItem key={id} value={id}>
                          {description ?? name}
                        </MenuItem>
                      ))}
                    </Select>
                    {meta.error && meta.touched ? (
                      <FormHelperText>{meta.error}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid xs={6} item>
              <Field name="paymentType">
                {({ input, meta }) => (
                  <FormControl
                    variant="filled"
                    error={meta.error && meta.touched}
                    fullWidth
                  >
                    <InputLabel>Тип платежа</InputLabel>
                    <Select
                      fullWidth
                      value={input.value.id}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                      }}
                    >
                      {paymentTypes.map(([key, value], index) => (
                        <MenuItem key={index} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {meta.error && meta.touched ? (
                      <FormHelperText>{meta.error}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Grid>

            {!isPersonal ? (
              <Grid xs={6} item>
                <Field name="pupilsCount">
                  {({ input, meta }) => (
                    <ModalInput
                      label="Количество учеников"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      variant="filled"
                      type="text"
                      {...input}
                    />
                  )}
                </Field>
              </Grid>
            ) : null}

            {!isPersonal ? (
              <Grid xs={6} item>
                <Field name="teachersCount">
                  {({ input, meta }) => (
                    <ModalInput
                      label="Количество учителей"
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      variant="filled"
                      type="text"
                      {...input}
                    />
                  )}
                </Field>
              </Grid>
            ) : null}

            <Grid xs={6} item>
              <Field name="total">
                {({ input, meta }) => (
                  <ModalInput
                    label={costText}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={12} item>
              <ButtonCustom type="submit" disabled={disableSubmit} width="200">
                Оформить подписку
              </ButtonCustom>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};
