import React from 'react';
import { useQuery } from "@apollo/client";
import { GetSchoolClassroomsDocument, GetSchoolClassroomsQuery } from "../../../generated/graphql";
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style";
import { LoadingBox } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";

interface IUserSchoolClasses{
  getAllItemsParams:{
    limit: number;
    skip: number;
  };
  schoolId:string;
  schoolClassroomId:string;
  setMobileUsers(schoolClassroomId:string):void;
}

export const UserSchoolClasses = ({getAllItemsParams,schoolId,schoolClassroomId,setMobileUsers}:IUserSchoolClasses) => {

  const {data,loading,error}=useQuery<GetSchoolClassroomsQuery>(GetSchoolClassroomsDocument,{
    variables:{
      ...getAllItemsParams,
      schoolId
    }
  })

  if (loading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }
  const allClasses=data?.getSchoolClassrooms?.schoolClassrooms||[];

  return (
    <Autocomplete
      options={allClasses?.map(({ id }) => id)}
      getOptionLabel={(option) =>
        `${allClasses?.find(({ id }) => id === option)?.grade || ""}` +
        " " +
        (allClasses?.find(({ id }) => id === option)?.letter || "")
      }
      value={allClasses?.find(({ id }) => id === schoolClassroomId)?.id || ""}
      onChange={(e, value) => setMobileUsers(value)}
      renderInput={(params) => (
        <UserFields {...params} label="Классы" variant="standard" />
      )}
    />
  );
};
