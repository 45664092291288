import { useRef } from "react";
import { useReactiveVar } from "@apollo/client";
import { ApplicationBundleTypeEnum, ApplicationMainModelFileTypeEnum, ModelFileTypeEnum } from "../../../../../generated/graphql";
import { mobileAppModel } from "../../state";
import { currentBundleFindHandler, getBundleStatus } from "../../utils";
import { BundleMode, BundleNames, bundleTypeItems } from "../../constants";
import { CurrentBundleContainer, MobileAppModalBundleName } from "../../../MobileAppModal.style";
import { Box, Collapse, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { CustomRadioGroup } from "../../../../shared/CustomRadioGroup";
import { ModalWithPhotoInput, BundleLink, ButtonCustom, ImportFileUpload } from "../../../../shared/Style/Style";
import { SaveIconButton } from "../../../../shared/Buttons/SaveIconButton";
import { CurrentBundleType } from "../../types";

export const AppModelBundles = () => {
    const bundlePhotoInput = useRef<HTMLInputElement | null>(null);
    const iosInput = useRef<HTMLInputElement | null>(null);
    const webGLInput = useRef<HTMLInputElement | null>(null);
    const androidInput = useRef<HTMLInputElement | null>(null);
    const vrExperimentInput = useRef<HTMLInputElement | null>(null);
    const manifestInputIos = useRef<HTMLInputElement | null>(null);
    const manifestInputWebGL = useRef<HTMLInputElement | null>(null);
    const manifestInputAndroid = useRef<HTMLInputElement | null>(null);
    const manifestInputVr = useRef<HTMLInputElement | null>(null);
    
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);
    const language = useReactiveVar(mobileAppModel.language);
    const model = useReactiveVar(mobileAppModel.model);
  
    if (!model) return null;
    
    const { id, bundles, bundleType, bundleLink, bundlePhoto } = model;

    const currentBundleIos = currentBundleFindHandler(
        bundles,
        ApplicationMainModelFileTypeEnum.Ios,
        language
    );
    const currentBundleWebGL = currentBundleFindHandler(
        bundles,
        ApplicationMainModelFileTypeEnum.WebGl,
        language
    );
    const currentBundleAndroid = currentBundleFindHandler(
        bundles,
        ApplicationMainModelFileTypeEnum.Android,
        language
    );
    const currentBundleVr = currentBundleFindHandler(
        bundles,
        ApplicationMainModelFileTypeEnum.Vr,
        language
    );
    
    const bundlesData = [
        {
            name: BundleNames.Ios, currentBundle: currentBundleIos, type: BundleMode.Bundle,
            inputRef: iosInput, handleBundle: (file: File) => mobileAppModel.createBundleFile(id, language, ApplicationMainModelFileTypeEnum.Ios, file),
        },
        {
            name: BundleNames.WebGL, currentBundle: currentBundleWebGL, type: BundleMode.Bundle,
            inputRef: webGLInput, handleBundle: (file:File) =>  mobileAppModel.createBundleFile(id, language, ApplicationMainModelFileTypeEnum.WebGl, file),
        },
        {
            name: BundleNames.Android, currentBundle: currentBundleAndroid, type: BundleMode.Bundle,
            inputRef: androidInput, handleBundle: (file:File) => mobileAppModel.createBundleFile(id, language, ApplicationMainModelFileTypeEnum.Android, file),
        },
        {
            name: BundleNames.Vr, currentBundle: currentBundleVr, type: BundleMode.Bundle, inputName: "vrExperimentInput",
            inputRef: vrExperimentInput, handleBundle: (file:File) => mobileAppModel.createBundleFile(id, language, ApplicationMainModelFileTypeEnum.Vr, file),
        },
        {
            name: BundleNames.IosManifest, currentBundle: currentBundleIos, type: BundleMode.Manifest,
            inputRef: manifestInputIos, handleBundle: (file:File) => mobileAppModel.updateModalFile(id, currentBundleIos?.id, file),
        },
        {
            name: BundleNames.WebGlManifest, currentBundle: currentBundleWebGL, type: BundleMode.Manifest,
            inputRef: manifestInputWebGL, handleBundle: (file:File) => mobileAppModel.updateModalFile(id, currentBundleWebGL?.id, file),
        },
        {
            name: BundleNames.AndroidManifest, currentBundle: currentBundleAndroid, type: BundleMode.Manifest, inputName: "manifestInputAndroid",
            inputRef: manifestInputAndroid, handleBundle: (file:File) => mobileAppModel.updateModalFile(id, currentBundleAndroid?.id, file),
        },
        {
            name: BundleNames.VrManifest, currentBundle: currentBundleVr, type: BundleMode.Manifest, inputName: "manifestInputVr",
            inputRef: manifestInputVr, handleBundle: (file:File) => mobileAppModel.updateModalFile(id, currentBundleVr?.id, file),
        },
    ];

    const renderCurrentBundle = (
        name: BundleNames,
        currentBundle: CurrentBundleType,
        type:  BundleMode,
        inputRef: React.RefObject<HTMLInputElement>,
        handleBundle: (file: File) => void,
    ) => {
        const bundleVariant = type === BundleMode.Manifest ? currentBundle?.manifest : currentBundle?.file;
        const label = type === BundleMode.Manifest ? `${name}` : `${name} ${type}`;
        const isDisabledManifest = type === BundleMode.Manifest && !currentBundle;
        const { isDisabledVrBundle, isDisabledOtherBundles } = getBundleStatus(bundles, name, type);

        const isDisabledInput = isLoading || isDisabledManifest || !language || isDisabledVrBundle || isDisabledOtherBundles;

        return (
            <CurrentBundleContainer key={name}>
                <MobileAppModalBundleName>{name}</MobileAppModalBundleName>

                {!!bundleVariant ? (
                    <span>
                        <BundleLink href={bundleVariant}>{label}</BundleLink>

                        <IconButton
                            disabled={isLoading}
                            onClick={() => mobileAppModel.deleteBundleFile(id, currentBundle?.id)}
                        >
                            <Delete color="secondary" />
                        </IconButton>
                    </span>
                ) : (
                      <ButtonCustom
                        disabled={isDisabledInput}
                        onClick={() => inputRef.current.click()}
                      >
                      <span>Прикрепить</span>
                      <ImportFileUpload
                        type="file"
                        ref={inputRef}
                        onChange={({ target: { files } }) =>
                          files[0] && handleBundle(files[0])
                        }
                      />
                    </ButtonCustom>                         
                )}

            </CurrentBundleContainer>
        );
    };

    const bundleTypeHandler = (value: {type: ApplicationBundleTypeEnum}) => {
        mobileAppModel.setBundleType(value.type);
    };

    return (
        <>
            {/* 
            //TODO: temporarily unavailable components AD-184

            <CustomRadioGroup
                disabled={isLoading}
                items={bundleTypeItems}
                chosenItem={bundleType}
                state={{ type: bundleType }}
                setNewState={bundleTypeHandler}
                fieldName="type"
                horizontal={true}
            />

            <Collapse
                in={bundleType === ApplicationBundleTypeEnum.BundleLink}
                collapsedSize="0px"
            >
                <ModalWithPhotoInput
                    disabled={isLoading}
                    fullWidth
                    label="Ссылка"
                    value={bundleLink}
                    onChange={({ target: { value } }) => mobileAppModel.setBundleLink(value)}
                    defaultValue={bundleLink || ""}
                    InputProps={{
                        endAdornment: (
                            <SaveIconButton
                                disabled={isLoading}
                                save={() => mobileAppModel.updateBundleLink(id, bundleLink)}
                            />
                        ),
                    }}
                />
            </Collapse>

            <Collapse
                in={bundleType === ApplicationBundleTypeEnum.Photo}
                collapsedSize="0px"
            >
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {bundlePhoto ? (
                        <span>
                            <PrimaryLink href={bundlePhoto}>Картинка</PrimaryLink>

                            <IconButton
                                disabled={isLoading}
                                onClick={() => mobileAppModel.deleteAppModelFiles( id, ModelFileTypeEnum.BundlePhoto )}
                            >
                                <Delete color="secondary" />
                            </IconButton>
                        </span>
                    ) : (
                            <ButtonCustom
                                onClick={() => bundlePhotoInput.current.click()}
                                disabled={isLoading}
                            >
                            <span>Прикрепить</span>
                            <ImportFileUpload
                                type="file"
                                accept="image/png, image/jpeg"
                                ref={bundlePhotoInput}
                                onChange={({ target: { files } }) =>
                                  files[0] && mobileAppModel.updateBundlePhoto(id, files[0])
                                }
                            />
                        </ButtonCustom>                            
                    )}
                </Box>
            </Collapse> */}

            <Collapse
                in={bundleType === ApplicationBundleTypeEnum.Bundle}
                collapsedSize="0px"
            >
                {bundlesData.map(({ name, currentBundle, type, inputRef, handleBundle }) =>
                    renderCurrentBundle(
                        name,
                        currentBundle,
                        type,
                        inputRef,
                        handleBundle,
                    ))
                }
            </Collapse>
        </>
    );
};
