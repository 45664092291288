import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  School as SchoolModel,
  useGetAchieveLazyQuery,
} from "../../generated/graphql";
import { SchoolPaths } from "../shared/constants";
import {
  ApolloQueryResult,
  DocumentNode,
  useLazyQuery,
  useQuery,
} from "@apollo/client";

interface SchoolData extends SchoolModel {
  file?: File | null;
}

interface SchoolError {
  [key: string]: boolean;
}

const initialState: SchoolData = {
  file: null,
  photo: "",
  name: "",
  phone: "",
  email: "",
  admins: [],
  managerId: "",
  cityId: "",
  regionId: "",
  districtId: "",
  countryId: "",
};

const errorState: SchoolError = {
  name: false,
  phone: false,
  email: false,
  admins: false,
  manager: false,
  country: false,
  region: false,
  city: false,
};

export const useSetSchoolData = (schoolDataUpdate: SchoolModel) => {
  const [schoolData, setSchoolData] = useState<SchoolData>(
    schoolDataUpdate || initialState
  );
  return { schoolData, setSchoolData };
};

export const useUpdateSchoolData = (
  schoolDataUpdate: SchoolModel,
  setSchoolData
) => {
  useEffect(() => {
    const {
      name,
      phone,
      email,
      admins,
      managerId,
      countryId,
      regionId,
      cityId,
      photo,
    } = schoolDataUpdate ?? {};

    if (schoolDataUpdate) {
      setSchoolData((prev) => ({
        ...prev,
        name,
        phone,
        email,
        admins,
        managerId,
        countryId,
        regionId,
        cityId,
        photo,
      }));
    }
  }, [schoolDataUpdate]);
};

export const useSchoolErrors = () => {
  const [schoolErrors, setSchoolErrors] = useState(errorState);
  return { schoolErrors, setSchoolErrors };
};

export const useSchoolRedirect = (isPersonal: boolean) => {
  const { pathname } = useLocation();
  const [redirect, setRedirect] = useState({
    toSchool: false,
    toPersonal: false,
  });

  useEffect(() => {
    if (pathname.includes(`/${SchoolPaths.school}`) && isPersonal === true) {
      setRedirect((prev) => ({ ...prev, toPersonal: true }));
    }

    if (pathname.includes(`/${SchoolPaths.personal}`) && isPersonal === false) {
      setRedirect((prev) => ({ ...prev, toSchool: true }));
    }
  }, [pathname, isPersonal]);

  return redirect;
};
interface UseInfiniteScrollProps<T> {
  query: DocumentNode;
  variables: Record<string, any>;
  getItems: (data: T) => any[];
  limit: number;
  key: string;
}

export const useInfiniteScrollForQuery = <T>({
  query,
  variables,
  getItems,
  limit,
  key,
}: UseInfiniteScrollProps<T>) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const [initialItemsFetch, { loading, error, fetchMore }] =
    useLazyQuery<T>(query);

  const loadMore = useCallback(async () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const result: ApolloQueryResult<T> = await fetchMore({
      variables: {
        [key]: { ...variables, limit, skip: nextPage * limit },
      },
    });

    const newItems = getItems(result.data);
    setItems((prevItems) => Array.from(new Set([...prevItems, ...newItems])));
    setHasMore(newItems.length === limit);
  }, [page, fetchMore, variables, limit, getItems, key]);

  const handleScroll = useCallback(
    (event) => {
      const listboxNode = event.target as HTMLElement;
      const bottom =
        listboxNode.clientHeight + listboxNode.scrollTop ===
        listboxNode.scrollHeight;

      if (bottom && hasMore) {
        loadMore();
      }
    },
    [hasMore, loadMore]
  );

  useEffect(() => {
    initialItemsFetch({
      variables: { [key]: { ...variables, limit } },
      onCompleted: (data) => {
        const newItems = getItems(data);
        setItems(newItems);
        setHasMore(newItems.length === limit);
      },
    });
  }, [variables]);

  return { items, loading, error, handleScroll };
};
