import { FC } from "react";
import { mobileAppModel } from "../../state";
import { getEmbedUrl } from "../../utils";
import { regexYouTubeUrl, videoIdGroupIndex } from "../../constants";
import DeleteIcon from "@material-ui/icons/Delete";
import { CardMediaContainer, DeleteButtonContainer, DeleteMediaButton } from "./Style";

interface MediaCardFilledProps {
  mediaURL: string;
  id: string;
};

export const MediaCardFilled: FC<MediaCardFilledProps> = ({
    id,
    mediaURL,
}) => {

  const handleDelete = () => {
      if (id) {
        mobileAppModel.deleteContentPreview(id);
    }
  };

  const videoURL = mediaURL.match(regexYouTubeUrl);

  return (
    <CardMediaContainer>
      {videoURL ? (
        <iframe
          src={getEmbedUrl(videoURL[videoIdGroupIndex])}
          title="YouTube video player"
          loading="lazy"
        />
      ) : (
        <img
          src={mediaURL}
          alt="Lesson preview"
          loading="lazy"
        />
      )}

      <DeleteButtonContainer>
        <DeleteMediaButton onClick={handleDelete}>
          <DeleteIcon fontSize={"medium"} />
          Удалить
        </DeleteMediaButton>
      </DeleteButtonContainer>
    </CardMediaContainer>
  );
};
