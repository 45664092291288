import {
  Payment,
  SubscriptionPlan,
  SubscriptionPlanTypeEnum,
  PaymentType,
} from "../../../generated/graphql";

const filterBasePlan = (plans: SubscriptionPlan[]) => plans.filter(({ name }) => name !== "BASE_V2");

export const getSchoolSubscriptionPlans = (plans: SubscriptionPlan[]) => {
  return filterBasePlan(plans).filter(({ type }) => type === SubscriptionPlanTypeEnum.ForSchool);
};

export const getPersonalSchoolSubscriptionPlans = (plans: SubscriptionPlan[]) => {
  return filterBasePlan(plans).filter(({ type }) => type === SubscriptionPlanTypeEnum.ForTeacher);
};

export const TODAY_DATE = new Date(new Date().setHours(0, 0, 0, 0));

export const findActivePaymentId = (payments: Payment[] = [], dateToCompare: Date) => {
  const dateTime = dateToCompare.getTime();
  return payments.find(({ expiredDate }) => new Date(expiredDate).getTime() > dateTime)?.id;
};

export const getActivePayments = (payments: Payment[] = []) => {
  return payments.filter(({ expiredDate }) => new Date(expiredDate).getTime() >= TODAY_DATE.getTime());
};

export const getPaymentsHistory = (payments: Payment[] = []) => {
  return payments.filter(({ expiredDate }) => new Date(expiredDate).getTime() < TODAY_DATE.getTime());
};

export const isExternalPayment = (paymentType: PaymentType) => {
  return paymentType === PaymentType.Fondy || paymentType === PaymentType.Stripe || paymentType === PaymentType.Liqpay;
};

export const getFormattedError = (errorMessage: string) => errorMessage.trim().replace(/\s+/g, ' ');