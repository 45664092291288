import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApolloError, useApolloClient, useReactiveVar } from "@apollo/client";
import { useRequestError } from "../../../hooks";
import { validateInputs } from "./utils";
import { mobileAppModel } from "./state";
import { LoaderProps } from "./constants";
import { Dialog } from "@material-ui/core";
import {
  MobileAppPhotoDropzone,
  ModelModalTitle,
} from "../MobileAppModal.style";
import {
  ModalWithPhotoContent,
  ModalWithPhotoInputs,
} from "../../shared/Style/Style";
import {
  LoadingProgress,
  LoadingProgressOverlay,
} from "../../shared/LoadingProgress";
import { CloseIconButton } from "../../shared/Buttons/CloseIconButton";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { ModelForm } from "./components/ModelForm";
import { ModalControlButtons } from "./components/ModalControlButtons";

interface IMobileAppModalProps {
  open: boolean;
  close(): void;
  refetch(): void;
  modelId: string;
  refetchVariables: {
    limit?: number;
    skip?: number;
    applicationMainId: string;
    isPublished?: boolean;
  };
}

export const MobileAppModelModal = ({
  open,
  close,
  modelId,
  refetchVariables,
  refetch,
}: IMobileAppModalProps) => {
  const { appId, pageId } = useParams<{ appId: string; pageId: string }>();
  const apolloClient = useApolloClient();
  mobileAppModel.initMobileAppModel(apolloClient);

  useEffect(() => {
    if (modelId) {
      mobileAppModel.getApplicationModels(modelId);
      mobileAppModel.getSchoolGrades();
      mobileAppModel.getEducationAreas();
    }
    if (pageId && appId) {
      mobileAppModel.getApplicationThemes(pageId);
      mobileAppModel.getSchoolSubjects(appId);
    }
  }, [appId, modelId, pageId]);

  const language = useReactiveVar(mobileAppModel.language);
  const isModelLoading = useReactiveVar(mobileAppModel.loading);
  const isUpdateLoading = useReactiveVar(mobileAppModel.updateLoading);
  const error = useReactiveVar(mobileAppModel.error);
  const model = useReactiveVar(mobileAppModel.model);

  const { requestError, setRequestError, errorMessage } = useRequestError(
    error.error as ApolloError,
    error.message
  );

  const uploadPhotoHandler = (file: File) => {
    if (file) {
      mobileAppModel.updateModelPhoto(modelId, file);
    }
  };

  const uploadPhotoError = (error: boolean, message: string) => {
    mobileAppModel.setError(error, message);
  };

  const closeHandler = () => {
    mobileAppModel.clearModel();
    close();
  };

  const cancelHandler = () => {
    refetch();
    closeHandler();
  };

  const saveModelHandler = async () => {
    if (!model) return;
    const isValid = validateInputs(model, language);
    if (!isValid) return;

    await mobileAppModel.updateAppModel();

    if (!error.error && !isUpdateLoading) return true;
  };

  const updateAppModelInMarket = async () => {
    if (model?.isUploadedToMarket) {
      return await mobileAppModel.updateApplicationModelInMarketContent();
    }

    return await mobileAppModel.createApplicationModelInMarketContent();
  };

  const addToMarketHandler = async () => {
    const isSavedModel = await saveModelHandler();

    if (!isSavedModel) return;

    await updateAppModelInMarket();

    if (!error.error && !isUpdateLoading) cancelHandler();
  };

  const title =
    model?.name === "Назва експерименту" && !model?.description
      ? "Создание"
      : "Редактирование";

  if (isModelLoading && !isUpdateLoading) {
    return (
      <Dialog open={isModelLoading} PaperProps={LoaderProps}>
        <LoadingProgress />
      </Dialog>
    );
  }

  return (
    <>
      {model && (
        <Dialog open={open} maxWidth="md">
          <ModalWithPhotoContent>
            {isUpdateLoading && <LoadingProgressOverlay />}

            <CloseIconButton close={cancelHandler} />

            <ModelModalTitle>{title}</ModelModalTitle>

            <ModalWithPhotoInputs>
              <MobileAppPhotoDropzone
                photo={model?.photo}
                setNewPhoto={uploadPhotoHandler}
                showError={uploadPhotoError}
              />

              <ModelForm />
            </ModalWithPhotoInputs>

            <ModalControlButtons
              cancel={cancelHandler}
              saveModel={saveModelHandler}
              addToMarket={addToMarketHandler}
            />
          </ModalWithPhotoContent>
        </Dialog>
      )}

      <ErrorMessageSnackbar
        open={requestError}
        message={errorMessage}
        closeHandler={() => {
          setRequestError(false);
          mobileAppModel.setError(false, null);
        }}
      />
    </>
  );
};
