import { useEffect, useState, memo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSubscriptionPlanPaymentFromAdminPanelMutation,
  CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables,
  DeletePaymentMutation,
  GetPaymentsHistoryBySchoolQuery,
  GetSubscriptionPlansV2Query,
  HandlePaymentActivationMutation,
  HandlePaymentActivationMutationVariables,
} from "../../../generated/graphql";
import { SchoolPayments } from "./SchoolPayments";
import { SubscriptionForm } from "./SubscriptionForm";
import { useRequestError } from "../../../hooks";
import { ErrorMessageSnackbar } from "../../shared/ErrorMessageSnackbar";
import { SuccessSnackbar } from "../../shared/SuccessSnackbar";
import { SubscriptionContainer } from "../Style";
import { Subtitle } from "../../shared/Style/Style";
import { CircularProgress } from "@material-ui/core";
import {
  DocumentsMap,
  CustomErrorMessagesMap,
  GqlErrorMessagesMap,
  SchoolPaymentsEnum,
  NO_MANAGER_ERROR,
} from "./maps";
import { getSchoolSubscriptionPlans, getPersonalSchoolSubscriptionPlans, getFormattedError, } from "./utils";

interface SchoolSubscriptionProps {
  schoolId: string;
  isPersonal: boolean;
  disableFormSubmit: boolean;
  onSubscriptionError(field: string): void;
}

export const SchoolSubscription = memo(({
  schoolId,
  isPersonal,
  disableFormSubmit,
  onSubscriptionError
}: SchoolSubscriptionProps) => {
  const {
    data: subscriptionPlansData,
    loading: subscriptionPlansLoading,
    error: subscriptionPlansError,
  } = useQuery<GetSubscriptionPlansV2Query>(DocumentsMap.get(SchoolPaymentsEnum.subscriptionPlans), {
    fetchPolicy: "cache-and-network",
  });

  const {
    data: paymentsHistoryData,
    loading: paymentsHistoryLoading,
    error: paymentsHistoryError,
  } = useQuery<GetPaymentsHistoryBySchoolQuery>(DocumentsMap.get(SchoolPaymentsEnum.paymentsHistory), {
    variables: { schoolId },
    fetchPolicy: "cache-and-network",
  });

  const refetchPaymentsHistory = {
    query: DocumentsMap.get(SchoolPaymentsEnum.paymentsHistory), variables: { schoolId },
  };

  const [createPaymentFormAdminPanel, {
    data: createPaymentData,
    loading: createPaymentLoading,
    error: createPaymentError,
  }] = useMutation<CreateSubscriptionPlanPaymentFromAdminPanelMutation>(DocumentsMap.get(SchoolPaymentsEnum.createPayment), {
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [activatePayment, {
    data: paymentActivationData,
    loading: paymentActivationLoading,
    error: paymentActivationError,
  }] = useMutation<HandlePaymentActivationMutation>(DocumentsMap.get(SchoolPaymentsEnum.activatePayment), {
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [deletePayment, {
    data: paymentDeletionData,
    loading: paymentDeletionLoading,
    error: paymentDeletionError,
  }] = useMutation<DeletePaymentMutation>(DocumentsMap.get(SchoolPaymentsEnum.deletePayment), {
    refetchQueries: [refetchPaymentsHistory],
    errorPolicy: "all",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const plans = subscriptionPlansData?.getSubscriptionPlansV2 ?? [];
  const schoolPlans = getSchoolSubscriptionPlans(plans);
  const personalPlans = getPersonalSchoolSubscriptionPlans(plans);
  const subscriptionPlans = isPersonal ? personalPlans : schoolPlans;

  const payments = paymentsHistoryData?.getPaymentsHistoryBySchool?.payments ?? [];

  const mutationData =  createPaymentData?.createSubscriptionPlanPaymentFromAdminPanel
    || paymentActivationData?.handlePaymentActivation
    || paymentDeletionData?.deletePayment;

  const isLoading = subscriptionPlansLoading || paymentsHistoryLoading;
  const disableSubmit = disableFormSubmit
    || isLoading
    || createPaymentLoading
    || paymentActivationLoading
    || paymentDeletionLoading;
  const errors = subscriptionPlansError
    || paymentsHistoryError
    || createPaymentError
    || paymentActivationError
    || paymentDeletionError;

  const errorMessage = (errors && GqlErrorMessagesMap.get(getFormattedError(errors.message)))
    || (subscriptionPlansError && CustomErrorMessagesMap.get(SchoolPaymentsEnum.subscriptionPlans))
    || (paymentsHistoryError && CustomErrorMessagesMap.get(SchoolPaymentsEnum.paymentsHistory))
    || (createPaymentError && CustomErrorMessagesMap.get(SchoolPaymentsEnum.createPayment))
    || (paymentActivationError && CustomErrorMessagesMap.get(SchoolPaymentsEnum.activatePayment))
    || (paymentDeletionError && CustomErrorMessagesMap.get(SchoolPaymentsEnum.deletePayment));

  const { requestError, setRequestError } = useRequestError(errors);

  const handlePaymentCreation = async (variables: CreateSubscriptionPlanPaymentFromAdminPanelMutationVariables) => {
    try {
      await createPaymentFormAdminPanel({
        variables: {
          data: {
            ...variables.data,
            schoolId,
          },
        },
      });
    } catch(e) {
      console.error(e);
    }
  };

  const handlePaymentActivation = async (variables: HandlePaymentActivationMutationVariables) => {
    try {
      await activatePayment({ variables });
    } catch(e) {
      console.error(e);
    }
  };

  const handlePaymentDeletion = async (id: string) => {
    try {
      await activatePayment({ variables: { id, isActivated: false } });
      await deletePayment({ variables: { id } });
    } catch(e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (mutationData) {
      setOpenSnackbar(true);
    }
  }, [mutationData]);

  useEffect(() => {
    const isNoManagerError = errors && getFormattedError(errors.message) === NO_MANAGER_ERROR;
    
    if (isNoManagerError) {
      onSubscriptionError("manager");
    }
    
  }, [errors]);

  return (
    <>
      <SuccessSnackbar
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
      />

      <ErrorMessageSnackbar
        open={requestError}
        closeHandler={() => setRequestError(false)}
        message={errorMessage}
      />

      <SubscriptionContainer>
        <Subtitle>Подписки</Subtitle>
        <SubscriptionForm
          subscriptionPlans={subscriptionPlans}
          isPersonal={isPersonal}
          disableSubmit={disableSubmit}
          createPayment={handlePaymentCreation}
        />

        {!isLoading ? (
          <SchoolPayments
            payments={payments}
            isPersonal={isPersonal}
            activatePayment={handlePaymentActivation}
            deletePayment={handlePaymentDeletion}
          />
        ) : <CircularProgress color="inherit" />}
      </SubscriptionContainer>
    </>
  );
});
