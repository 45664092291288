import {
  GetCoursesForAdminPanelDocument,
  useAddCourseToSchoolMutation,
} from "../../../generated/graphql";

export const useAddCourse = (schoolId) => {
  const [addCourseToSchoolMutation] = useAddCourseToSchoolMutation({
    refetchQueries: [
      {
        query: GetCoursesForAdminPanelDocument,
        variables: { schoolId },
      },
    ],
    errorPolicy: "all",
  });
  return (schoolId, courseId) =>
    addCourseToSchoolMutation({
      variables: {
        schoolId,
        courseId,
      },
    });
};
