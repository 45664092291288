import { ApolloClient, ApolloError, makeVar } from "@apollo/client";
import {
  ApplicationBundleTypeEnum,
  ApplicationMain,
  ApplicationMainModel,
  ApplicationMainModelFileTypeEnum,
  ApplicationMainTheme,
  ContentTag,
  DeleteApplicationMainModelFilesDocument,
  EducationArea,
  LanguagesEnum,
  ContentTypeEnum,
  ModelFileTypeEnum,
  SchoolGrade,
  SetApplicationMainModelExperimentTypeMutationVariables,
  SmartNotification,
  SmartNotificationTypeEnum,
  SubscriptionPlanStatusEnum,
} from "../../../generated/graphql";
import {
  CREATE_MODEL_BUNDLE_FILE,
  DELETE_APPLICATION_MAIN_MODEL_FILES,
  DELETE_APPLICATION_MODEL_FILE,
  UPDATE_APPLICATION_MAIN_MODEL_FILE,
  UPDATE_APPLICATION_MODEL_BUNDLE,
  UPDATE_APPLICATION_MODEL_COLORING_PAGE_FILE,
  UPDATE_APPLICATION_MODEL_MAP_MARKER_PHOTO,
  UPDATE_APPLICATION_MODEL_MARKER_PHOTO,
  UPDATE_APPLICATION_MODEL_PHOTO,
} from "../../../graphql/mutations/applicationModelFiles";
import {
  CREATE_SMART_NOTIFICATION,
  DELETE_SMART_NOTIFICATION,
} from "../../../graphql/mutations/smartNotification";
import {
  CREATE_CONTENT_PREVIEW,
  DELETE_CONTENT_PREVIEW,
  SET_APPLICATION_MAIN_MODEL_EXPERIMENT_TYPE,
  UPDATE_APPLICATION_MAIN_MODEL,
} from "../../../graphql/mutations/applicationMainModel";
import {
  CREATE_APPLICATION_MODEL_IN_MARKET_CONTENT,
  UPDATE_APPLICATION_MODEL_IN_MARKET_CONTENT,
} from "../../../graphql/mutations/marketContent";
import { getFieldsToUpdate, getRefetchModelQueries, mapIds } from "./utils";
import {
  GET_APPLICATIONS_MAIN_FOR_SELECT,
  GET_APPLICATION_MAIN_MODEL,
  GET_CONTENT_TAG_BY_CONTENT_TYPE,
  GET_EDUCATION_AREAS,
} from "../../../graphql/queries/applicationMainModel";
import { GET_APPLICATION_MAIN_THEMES_BY_MAIN_ID } from "../../../graphql/queries/applicationMainTheme";
import { GET_SCHOOL_GRADES } from "../../../graphql/queries/school";
import { GET_SMART_NOTIFICATION } from "../../../graphql/queries/smartNotification";
import { BundleNames, ModelFields, SUBJECT_QUANTITY } from "./constants";

type FieldError = {
  error: boolean;
  message: string;
};

type InputErrors = {
  [key: string]: FieldError;
};

const initialInputErrors: InputErrors = {
  name: { error: false, message: "" },
  description: { error: false, message: "" },
  address: { error: false, message: "" },
  lat: { error: false, message: "" },
  longitude: { error: false, message: "" },
  language: { error: false, message: "" },
  contentType: { error: false, message: "" },
  mains: { error: false, message: "" },
  schoolGrades: { error: false, message: "" },
  tags: { error: false, message: "" },
  contentPreviews: { error: false, message: "" },
  libraryContent: { error: false, message: "" },
};

export class MobileAppMainModelState {
  public apolloClient: ApolloClient<any> | null = null;

  public initMobileAppModel(client: ApolloClient<any>) {
    this.apolloClient = client;
  }

  public model = makeVar<ApplicationMainModel | null>(null);

  public themes = makeVar<ApplicationMainTheme[]>([]);

  public schoolGradesList = makeVar<SchoolGrade[]>([]);

  public educationAreasList = makeVar<EducationArea[]>([]);

  public subjectCategoriesList = makeVar<ApplicationMain[]>([]);

  public smartNotifications = makeVar<SmartNotification>({});

  public language = makeVar<LanguagesEnum | null>(LanguagesEnum.Ukr);

  public loading = makeVar(false);

  public updateLoading = makeVar(false);

  public isTagsLoading = makeVar(false);

  public error = makeVar<{ error: ApolloError | boolean; message: string }>({
    error: false,
    message: "",
  });

  public inputErrors = makeVar<InputErrors>(initialInputErrors);

  public tagsList = makeVar<ContentTag[]>([]);

  private setLoading(status: boolean) {
    this.loading(status);
  }

  private setUpdateLoading(status: boolean) {
    this.updateLoading(status);
  }

  private setTagLoading(status: boolean) {
    this.isTagsLoading(status);
  }

  public setInputErrors(
    field: keyof InputErrors,
    error: boolean,
    message: string = "Заполните все обязательные поля"
  ) {
    this.inputErrors({ ...this.inputErrors(), [field]: { error, message } });
    if (!error) {
      this.setError(error, "");
    }
  }

  public setError(error: ApolloError | boolean, message: string | null) {
    this.error({ error, message });
  }

  public clearModel() {
    this.model(null);
    this.setTagsList([]);
    this.language(LanguagesEnum.Ukr);
    this.error({ error: false, message: "" });
    this.inputErrors(initialInputErrors);
  }

  public cleanUpTags() {
    this.setTags([]);
    this.setTagsList([]);
  }

  public setTitle(name: string) {
    this.model({ ...this.model(), name });
  }

  public setDescription(description: string) {
    this.model({ ...this.model(), description });
  }

  public setCoordinates(field: "address" | "lat" | "longitude", value: string) {
    this.model({ ...this.model(), [field]: value });
  }

  public setSubscriptionPlan(
    subscriptionPlan: SubscriptionPlanStatusEnum | null
  ) {
    this.model({ ...this.model(), subscriptionPlan });
  }

  public setContentType(type: ContentTypeEnum | null) {
    this.model({ ...this.model(), contentType: { name: type } });
  }

  public setBundleType(bundleType: ApplicationBundleTypeEnum | null) {
    this.model({ ...this.model(), bundleType });
  }

  public setBundleLink(bundleLink: string) {
    this.model({ ...this.model(), bundleLink });
  }

  public setIsMarker(isMarker: boolean) {
    this.model({ ...this.model(), isMarker });
  }

  public setMarkerWidth(markerWidth: number) {
    this.model({ ...this.model(), markerWidth });
  }

  public setIsContour(isContour: boolean) {
    this.model({ ...this.model(), isContour });
  }

  public setLanguage(language: LanguagesEnum | null) {
    this.language(language);
  }

  public setThemeId(themeId: string) {
    this.model({ ...this.model(), themeId });
  }

  public setSchoolGradeIds(ids: string[]) {
    const schoolGrades = this.schoolGradesList().filter((grade) =>
      ids.includes(grade.id)
    );
    this.model({ ...this.model(), schoolGrades });
  }

  public setEducationAreaIds(id: string[]) {
    const educationAreas = this.educationAreasList().filter((area) =>
      id.includes(area.id)
    );
    this.model({ ...this.model(), educationAreas });
  }

  public setSubjectCategories(ids: string[]) {
    const mains = this.subjectCategoriesList()
      .filter((subject) => ids.includes(subject.id))
      .map(({ id, name }) => ({ id, name }));
    this.model({ ...this.model(), mains });
  }

  public setInGradeOrder(inGradeOrder: number) {
    this.model({ ...this.model(), inGradeOrder });
  }

  public setShowSmartNotification(showSmartNotification: boolean) {
    this.model({ ...this.model(), showSmartNotification });
  }

  public setUserClicks(clicks: number) {
    this.model({ ...this.model(), clicks });
  }

  public setContentAuthor(contentAuthor: string) {
    this.model({ ...this.model(), contentAuthor });
  }

  public setTags(ids: string[]) {
    const tags = this.tagsList()
      .filter((tag) => ids.includes(tag.id))
      .map(({ id, name, displayName }) => ({ id, name, displayName }));
    this.model({ ...this.model(), tags });
  }

  public setTagsList(tags: ContentTag[]) {
    this.tagsList(tags);
  }

  public setEqualId(equalId: string) {
    this.model({
      ...this.model(),
      libraryContent: {
        ...this.model().libraryContent,
        equalId,
      },
    });
  }

  public setPrice(price: number) {
    this.model({
      ...this.model(),
      libraryContent: {
        ...this.model().libraryContent,
        price,
      },
    });
  }

  public getApplicationModels = async (id: string, fields?: string[]) => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_APPLICATION_MAIN_MODEL,
        variables: { id },
        fetchPolicy: "network-only",
      });
      const currentModel = data?.getApplicationMainModel || null;

      if (Array.isArray(fields) && currentModel) {
        const fieldsToUpdate = getFieldsToUpdate(fields, currentModel);
        this.model({ ...this.model(), ...fieldsToUpdate });
      } else {
        this.model(currentModel);
      }
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке модели произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getApplicationThemes = async (applicationMainId: string) => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_APPLICATION_MAIN_THEMES_BY_MAIN_ID,
        variables: { applicationMainId },
        fetchPolicy: "network-only",
      });
      this.themes(data?.getApplicationMainThemesByMainId);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке списка тем произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getSchoolGrades = async () => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_SCHOOL_GRADES,
        fetchPolicy: "network-only",
      });
      this.schoolGradesList(data?.getSchoolGrades);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке списка классов произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getSmartNotification = async (id: string) => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_SMART_NOTIFICATION,
        variables: { id },
        fetchPolicy: "network-only",
      });
      this.smartNotifications(data?.getSmartNotification);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке уведомления произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getEducationAreas = async () => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_EDUCATION_AREAS,
        fetchPolicy: "network-only",
      });
      this.educationAreasList(data?.getEducationAreas);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке списка направлений образования произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getSchoolSubjects = async (applicationId: string) => {
    try {
      this.setLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_APPLICATIONS_MAIN_FOR_SELECT,
        variables: { applicationId, limit: SUBJECT_QUANTITY },
        fetchPolicy: "network-only",
      });
      this.subjectCategoriesList(
        data?.getApplicationsMain?.applicationsMain || []
      );
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке списка предметов произошла ошибка"
      );
    } finally {
      this.setLoading(false);
    }
  };

  public getContentTag = async (contentType: ContentTypeEnum[]) => {
    try {
      this.setTagLoading(true);
      const { data } = await this.apolloClient.query({
        query: GET_CONTENT_TAG_BY_CONTENT_TYPE,
        variables: { contentType },
      });
      this.setTagsList(data?.getContentTagByContentType || []);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке списка тегов произошла ошибка"
      );
    } finally {
      this.setTagLoading(false);
    }
  };

  public updateModelPhoto = async (modelId: string, file: File) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_PHOTO,
        variables: { modelId, file },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [ModelFields.Photo]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке фотографии произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateMarkerPhoto = async (
    modelId: string,
    file: File,
    isMarker: Boolean
  ) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_MARKER_PHOTO,
        variables: { modelId, file, isMarker },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.IsMarker,
        ModelFields.MarkerPhoto,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке фотографии произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateMapMarker = async (modelId: string, file: File) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_MAP_MARKER_PHOTO,
        variables: { modelId, file },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.IsMap,
        ModelFields.MapMarkerPhoto,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При загрузке фотографии произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateBundlePhoto = async (modelId: string, file: File) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_BUNDLE,
        variables: {
          modelId,
          bundleType: ApplicationBundleTypeEnum.Photo,
          file,
        },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.BundleType,
        ModelFields.BundlePhoto,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении фотографии произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateBundleLink = async (modelId: string, bundleLink: string) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_BUNDLE,
        variables: {
          modelId,
          bundleType: ApplicationBundleTypeEnum.BundleLink,
          bundleLink,
        },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.BundleType,
        ModelFields.BundleLink,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении ссылки произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public createBundleFile = async (
    modelId: string,
    language: LanguagesEnum,
    type: ApplicationMainModelFileTypeEnum,
    file: File
  ) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: CREATE_MODEL_BUNDLE_FILE,
        variables: {
          fileData: {
            modelId,
            language,
            type,
            file,
          },
        },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.BundleType,
        ModelFields.Bundles,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении файла произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateModalFile = async (
    modelId: string,
    id: string,
    manifest: File
  ) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MAIN_MODEL_FILE,
        variables: {
          fileData: { id, manifest },
        },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.BundleType,
        ModelFields.Bundles,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении manifest произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public deleteBundleFile = async (modelId: string, fileId: string) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: DELETE_APPLICATION_MODEL_FILE,
        variables: { fileId },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.BundleType,
        ModelFields.Bundles,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При удалении бандла произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public deleteColoringPageFile = async () => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: DeleteApplicationMainModelFilesDocument,
        variables: {
          modelId: this.model().id,
          modelFileType: ModelFileTypeEnum.ColoringPageArFile,
        },
      });
      await this.getApplicationModels(this.model().id, [
        ModelFields.ColoringPageFile,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        `При удалении файла "${BundleNames.ColoringBookAR}" произошла ошибка`
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateColoringPageFile = async (file: File) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_COLORING_PAGE_FILE,
        variables: {
          modelId: this.model().id,
          coloringPageFile: file,
        },
      });
      await this.getApplicationModels(this.model().id, [
        ModelFields.ColoringPageFile,
      ]);
    } catch (error) {
      console.log(error);
      this.setError(
        error as ApolloError,
        `При загрузке файла "${BundleNames.ColoringBookAR}" произошла ошибка`
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public deleteAppModelFiles = async (
    modelId: string,
    modelFileType: ModelFileTypeEnum
  ) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: DELETE_APPLICATION_MAIN_MODEL_FILES,
        variables: { modelId, modelFileType },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      const fieldToUpdate =
        modelFileType === ModelFileTypeEnum.BundlePhoto
          ? [ModelFields.BundlePhoto]
          : modelFileType === ModelFileTypeEnum.MarkerPhoto
          ? [ModelFields.MarkerPhoto]
          : [ModelFields.MapMarkerPhoto];
      await this.getApplicationModels(modelId, fieldToUpdate);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При удалении файла произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public deleteSmartNotification = async (modelId: string, id: string) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: DELETE_SMART_NOTIFICATION,
        variables: { id },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.SmartNotificationId,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При удалении уведомления произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public createSmartNotification = async (
    description: string,
    type: SmartNotificationTypeEnum,
    id: string,
    modelId: string
  ) => {
    try {
      this.setUpdateLoading(true);
      await this.apolloClient.mutate({
        mutation: CREATE_SMART_NOTIFICATION,
        variables: {
          smartNotificationData: {
            description,
            type,
            applicationId: id,
            applicationMainModelId: modelId,
          },
        },
        refetchQueries: getRefetchModelQueries(modelId),
      });
      await this.getApplicationModels(modelId, [
        ModelFields.SmartNotificationId,
      ]);
      const smartNotificationId = this.model()?.smartNotificationId;
      await this.getSmartNotification(smartNotificationId);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При создании уведомления произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateAppModel = async () => {
    try {
      this.setUpdateLoading(true);
      const {
        id,
        name,
        description,
        address,
        lat,
        longitude,
        markerWidth,
        inGradeOrder,
        isContour,
        clicks,
        themeId,
        subscriptionPlan,
        showSmartNotification,
        schoolGrades,
        mains,
        contentAuthor,
        educationAreas,
        tags,
        libraryContent,
      } = this.model();
      const schoolGradeIds = mapIds(schoolGrades);
      const mainIds = mapIds(mains);
      const educationAreasIds = mapIds(educationAreas);
      const tagIds = mapIds(tags);
      const { equalId, price } = libraryContent;

      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MAIN_MODEL,
        variables: {
          modelData: {
            id,
            name,
            description,
            address,
            lat,
            longitude,
            markerWidth,
            inGradeOrder,
            isContour,
            clicks,
            themeId,
            subscriptionPlan,
            showSmartNotification,
            schoolGradeIds,
            mainIds,
            educationAreasIds,
            tagIds,
            contentAuthor,
            equalId,
            price,
          },
        },
      });
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении модели произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateApplicationModelInMarketContent = async () => {
    try {
      this.setUpdateLoading(true);
      const { childId } = this.model();
      const language = this.language();
      await this.apolloClient.mutate({
        mutation: UPDATE_APPLICATION_MODEL_IN_MARKET_CONTENT,
        variables: { id: childId, lang: language },
      });
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При обновлении модели на маркете произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public createApplicationModelInMarketContent = async () => {
    try {
      this.setUpdateLoading(true);
      const { id } = this.model();
      const language = this.language();
      await this.apolloClient.mutate({
        mutation: CREATE_APPLICATION_MODEL_IN_MARKET_CONTENT,
        variables: { id, lang: language },
      });
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении модели на маркет произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public createContentPreview = async (media: File | string) => {
    try {
      this.setUpdateLoading(true);
      const { id } = this.model();
      const field = media instanceof File ? "file" : "videoUrl";
      await this.apolloClient.mutate({
        mutation: CREATE_CONTENT_PREVIEW,
        variables: {
          contentLink: {
            experimentId: id,
          },
          [field]: media,
        },
      });
      await this.getApplicationModels(id, [ModelFields.ContentPreviews]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При сохранении изображения/ссылки произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public deleteContentPreview = async (deleteContentPreviewId: string) => {
    try {
      this.setUpdateLoading(true);
      const { id } = this.model();
      await this.apolloClient.mutate({
        mutation: DELETE_CONTENT_PREVIEW,
        variables: { deleteContentPreviewId },
      });
      await this.getApplicationModels(id, [ModelFields.ContentPreviews]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При удалении изображения/ссылки произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };

  public updateExperimentType = async (
    variables: SetApplicationMainModelExperimentTypeMutationVariables
  ) => {
    try {
      this.setUpdateLoading(true);
      const { id } = this.model();
      await this.apolloClient.mutate({
        mutation: SET_APPLICATION_MAIN_MODEL_EXPERIMENT_TYPE,
        variables,
      });
      await this.getApplicationModels(id, [
        ModelFields.ContentType,
        ModelFields.ColoringPageFile,
        ModelFields.Tags,
      ]);
    } catch (error) {
      this.setError(
        error as ApolloError,
        "При выборе типа контента произошла ошибка"
      );
    } finally {
      this.setUpdateLoading(false);
    }
  };
}

export const mobileAppModel = new MobileAppMainModelState();
