import { PaymentType } from "../../../../generated/graphql";

export type SubscriptionFormValues = {
  paymentDate: string,
  validity: string,
  paymentType: PaymentType | "",
  planId: string,
  pupilsCount: string,
  teachersCount: string,
  total: string,
}

export const validateForm = (values: SubscriptionFormValues, isPersonal: boolean) => {
  const errors: { [key: string]: string } = {};
  const skipValues = ["pupilsCount", "teachersCount"];

  for (let key in values) {
    if (values[key] === "total") continue;
    if (skipValues.includes(values[key]) && isPersonal) continue;

    if (!values[key]) {
      errors[key] = "Это поле обязательно";
    }
  }

  if (values.validity?.length >= 1 && (Number(values.validity) % 1 !== 0 || Number(values.validity) < 1)) {
    errors.validity = "Значение должно быть >= 1";
  }

  if (isNaN(Number(values.validity))) {
    errors.validity = "Значение должно быть числом";
  }

  if (values.pupilsCount?.length > 0 && Number(values.pupilsCount) <= 0) {
    errors.price = "Значение должно быть больше 0";
  }

  if (isNaN(Number(values.pupilsCount))) {
    errors.price = "Значение должно быть числом";
  }

  if (values.teachersCount?.length > 0 && Number(values.teachersCount) <= 0) {
    errors.price = "Значение должно быть больше 0";
  }

  if (isNaN(Number(values.teachersCount))) {
    errors.price = "Значение должно быть числом";
  }

  if (values.total?.length > 0 && Number(values.total) < 0) {
    errors.price = "Значение не должно быть меньше  0";
  }

  if (isNaN(Number(values.total))) {
    errors.price = "Значение должно быть числом";
  }

  // TODO: Fix this workaround
  delete errors.total
  if (isPersonal) {
    delete errors.pupilsCount;
    delete errors.teachersCount;
  }

  return errors;
};
