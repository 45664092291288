import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import {
  AllowedAdminPanelPaymentType,
  CreatePurchasePaymentFromAdminPanelMutationVariables,
} from "../../../../generated/graphql";
import { validateForm } from "./utils";
import { PurchaseFormValues } from "./types";
import { formInitialValues } from "./constants";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { CommonPaymentTypeMap } from "../../SchoolSubscription/maps";
import { ButtonCustom, ModalInput } from "../../../shared/Style/Style";
import { costText } from "../../constants";
import { FormApi } from "final-form";

interface GoodsFormProps {
  loading: boolean;
  createPurchasePayment(
    variables: CreatePurchasePaymentFromAdminPanelMutationVariables,
    reset: () => void
  ): void;
}
export const PurchaseForm = ({
  loading,
  createPurchasePayment,
}: GoodsFormProps) => {
  const apolloClient = useApolloClient();
  const { schoolId } = useParams<{ schoolId: string }>();

  const onFormValidation = (values: PurchaseFormValues) =>
    validateForm(values, apolloClient);

  const onFormSubmit = async (
    values: PurchaseFormValues,
    form: FormApi<PurchaseFormValues, PurchaseFormValues>
  ) => {
    const { paymentDate, productId, paymentType, price } = values;

    createPurchasePayment(
      {
        paymentDate,
        productId: Number(productId),
        paymentType: paymentType as AllowedAdminPanelPaymentType,
        price: +Number(price).toFixed(2),
        schoolId,
      },
      form.restart
    );
  };

  return (
    <Form
      initialValues={formInitialValues}
      validate={onFormValidation}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" spacing={4}>
            <Grid xs={3} item>
              <Field name="paymentDate">
                {({ input, meta }) => (
                  <DatePicker
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    fullWidth
                    variant="inline"
                    format="dd.MM.yyyy"
                    label="Дата платежа"
                    inputVariant="filled"
                    value={input.value || null}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={3} item>
              <Field name="paymentType">
                {({ input, meta }) => (
                  <FormControl
                    variant="filled"
                    error={meta.error && meta.touched}
                    fullWidth
                  >
                    <InputLabel>Тип оплаты</InputLabel>
                    <Select
                      fullWidth
                      value={input.value}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                      }}
                    >
                      {Array.from([...CommonPaymentTypeMap]).map(
                        ([key, value], index) => (
                          <MenuItem key={index} value={key}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {meta.error && meta.touched ? (
                      <FormHelperText>{meta.error}</FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid xs={3} item>
              <Field name="productId">
                {({ input, meta }) => (
                  <ModalInput
                    label="ID товара"
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={3} item>
              <Field name="price">
                {({ input, meta }) => (
                  <ModalInput
                    label={costText}
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    variant="filled"
                    type="text"
                    {...input}
                  />
                )}
              </Field>
            </Grid>

            <Grid xs={12} item>
              <ButtonCustom type="submit" disabled={loading} width="200">
                Добавить
              </ButtonCustom>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  );
};
