import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { LanguagesEnum } from "../../../../../generated/graphql";
import { Autocomplete } from "@material-ui/lab";
import { ModalInput } from "../../../../shared/Style/Style";


export const SelectLanguage = () => { 
    const language = useReactiveVar(mobileAppModel.language);
    const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
    const isLoading = useReactiveVar(mobileAppModel.updateLoading);

    return (
        <Autocomplete
          disabled={isLoading}
          options={Object.values(LanguagesEnum)}
          value={language}
          onChange={(_, value) => mobileAppModel.setLanguage((value as LanguagesEnum))}
          onFocus={() =>
            mobileAppModel.setInputErrors("language", false)
          }
          getOptionLabel={(option) => Object.values(LanguagesEnum).find(item => item === option) || ""}
          renderInput={(params) => {
            return (
              <ModalInput
                {...params}
                error={inputErrors.language.error}  
                margin="normal"
                fullWidth
                label="Язык"
                required
              />
            );
          }}
        />
    );
};